.App {
  /* text-align: center; */
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  max-width: 1208px;

  @media (min-width: 320px) and (max-width: 1200px) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.modal {
  background: #11111199;
}

.modal-header .close {
  display: none;
}

.modal-body {
  padding: 0;
  max-height: calc(100vh - 130px);
  overflow: auto;
}